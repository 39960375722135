@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');

h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, var,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video, ul, li {
	margin:0;
	padding:0;
	border:0;
	outline:0;
	font-size:100%;
	vertical-align:baseline;
	background:transparent;
	list-style:none;
}

article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section {
	display:block;
}

*, *:before, *:after {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-o-box-sizing: border-box;
	box-sizing: border-box;
	outline:none;
    position: relative;
}

.clearfix:after {
	content: ".";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}

.clearfix {
	display: block;
}

html, body {
	width:100%;
	height:100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
}

body {
	font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.5;
	color:#142357;
	padding:0;
	margin:0;
	text-size-adjust: none ;
	-webkit-text-size-adjust:none;
}

img {
	display:block;
	max-width:100%;
	margin:0 auto;
	height:auto;
}

a{
    display:inline-block;
	color:#142357;
	text-decoration:none;
}

a:hover{
	color:#142357;
}

a.more{
	font-family: moret, serif;
	font-size:24px;
    font-style: normal;
	font-weight:700;
	padding-right:30px;
	margin-top:30px;
	background:url(../images/more-arrow.svg) 100% 12px no-repeat;
	background-size:18px;
}

h1, h2, h3, h4, h5, h6{
    font-family: moret, serif;
    font-weight: 400;
    font-style: normal;
}

h2{
    font-size:90px;
    line-height:1.15;
}

h3{
    font-size:80px;
    line-height:1.15;
}

h4{
    font-size:60px;
    line-height:1.15;
}

h5{
	font-family: 'Poppins', sans-serif;
	font-weight:300;
    font-size:40px;
    line-height:1.15;
}

h6{
    font-size:24px;
    line-height:1.15;
}

p{
	font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.5;
}

.container{
	width:100%;
	max-width:1180px;
	margin:0 auto;
}

.psm-section{
	float:left;
	padding:100px 0;
	width:100%;
}

.loader{
	color:#ffffff;
	position:fixed;
	left:0;
	top:0;
	display:flex;
	justify-content:center;
	align-items:center;
	width:100%;
	height:100vh;
	background: rgb(50,79,62);
	background: -moz-linear-gradient(45deg,  rgba(50,79,62,1) 0%, rgba(16,39,90,1) 100%);
	background: -webkit-linear-gradient(45deg,  rgba(50,79,62,1) 0%,rgba(16,39,90,1) 100%);
	background: linear-gradient(45deg,  rgba(50,79,62,1) 0%,rgba(16,39,90,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#324f3e', endColorstr='#10275a',GradientType=1 );
}

.loader .loading {
	width: 80px;
	height: 50px;
	position: relative;
  }
  .loader .loading p {
	top: 0;
	padding: 0;
	margin: 0;
	color: #5389a6;
	font-family: "Oxygen", sans-serif;
	animation: text 3.5s ease both infinite;
	font-size: 12px;
	letter-spacing: 1px;
  }
  @keyframes text {
	0% {
	  letter-spacing: 1px;
	  transform: translateX(0px);
	}
	40% {
	  letter-spacing: 2px;
	  transform: translateX(26px);
	}
	80% {
	  letter-spacing: 1px;
	  transform: translateX(32px);
	}
	90% {
	  letter-spacing: 2px;
	  transform: translateX(0px);
	}
	100% {
	  letter-spacing: 1px;
	  transform: translateX(0px);
	}
  }
  .loader .loading span {
	background-color: #5389a6;
	border-radius: 50px;
	display: block;
	height: 16px;
	width: 16px;
	bottom: 0;
	position: absolute;
	transform: translateX(64px);
	animation: loading 3.5s ease both infinite;
  }
  .loader .loading span:before {
	position: absolute;
	content: "";
	width: 100%;
	height: 100%;
	background-color: #a6dcee;
	border-radius: inherit;
	animation: loading2 3.5s ease both infinite;
  }
  @keyframes loading {
	0% {
	  width: 16px;
	  transform: translateX(0px);
	}
	40% {
	  width: 100%;
	  transform: translateX(0px);
	}
	80% {
	  width: 16px;
	  transform: translateX(64px);
	}
	90% {
	  width: 100%;
	  transform: translateX(0px);
	}
	100% {
	  width: 16px;
	  transform: translateX(0px);
	}
  }
  @keyframes loading2 {
	0% {
	  transform: translateX(0px);
	  width: 16px;
	}
	40% {
	  transform: translateX(0%);
	  width: 80%;
	}
	80% {
	  width: 100%;
	  transform: translateX(0px);
	}
	90% {
	  width: 80%;
	  transform: translateX(15px);
	}
	100% {
	  transform: translateX(0px);
	  width: 16px;
	}
  }

header{
	position:absolute;
	left:0;
	top:0;
	padding:50px;
	width:100%;
	z-index:1;
}

header .container{
	display:flex;
	justify-content:space-between;
	align-items:flex-start;
}

header .logo{
	font-family: moret, serif;
	font-size: 24px;
	line-height: 1.15;
	color:#ffffff;
	text-transform:uppercase;
	position:relative;
	z-index:1;
}

header .logo a{
	color:#ffffff;
}

header .logo img{
	width:180px;
	height:auto;
}

.burger {
	height: 2.3em;
	width: 3em;
	margin-left: 15px;
	font-size: 12px;
	cursor: pointer;
	transition: 0.2s all;
	display: none;
	position:relative;
	z-index:1;
}

.burger:after {
	content: '';
	display: block;
	position: absolute;
	height: 150%;
	width: 150%;
	top: -25%;
	left: -25%;
}

.burger .burger-lines {
	top: 50%;
	margin-top: -0.125em;
}

.burger .burger-lines,
.burger .burger-lines:after,
.burger .burger-lines:before {
	pointer-events: none;
	display: block;
	content: '';
	width: 100%;
	border-radius: 0.25em;
	background-color: #ffffff;
	height: 0.18em;
	position: absolute;
	transform: rotate(0);
}

.burger .burger-lines .burger-lines:after {
	left: 0;
	top: -1em;
}

.burger .burger-lines .burger-lines:before {
	left: 1em;
	top: 1em;
}

.burger.burger-squeeze .burger-lines,
.burger.burger-squeeze .burger-lines:after,
.burger.burger-squeeze .burger-lines:before {
	transition: 0.2s top 0.2s, 0.1s left, 0.2s transform, 0.4s background-color 0.2s;
}

.burger.burger-squeeze .burger-lines:after,
.burger.burger-squeeze .burger-lines:before {
	/* width: 2em; */
}

.burger.burger-squeeze .burger-lines:after {
	left: 0;
	top: -0.8em;
}

.burger.burger-squeeze .burger-lines:before {
	left: 0;
	top: 0.8em;
}

.burger.burger-squeeze.open .burger-lines,
.burger.burger-squeeze.open .burger-lines:after,
.burger.burger-squeeze.open .burger-lines:before {
	transition: 0.2s background-color, 0.2s top, 0.2s left, 0.2s transform 0.15s;
}

.burger.burger-squeeze.open .burger-lines {
	background-color: transparent;
}

.burger.burger-squeeze.open .burger-lines:before,
.burger.burger-squeeze.open .burger-lines:after {
	left: 0.5em;
	top: 0px;
}

.burger.burger-squeeze.open .burger-lines:before {
	transform: rotate(-45deg);
}

.burger.burger-squeeze.open .burger-lines:after {
	transform: rotate(45deg);
}

header nav ul li{
	display:inline-block;
	margin-left:30px;
}

header nav ul li a{
	font-size:16px;
	color:#ffffff;
	text-transform:uppercase;
	transition:all .5s ease;
	-webkit-transition:all .5s ease;
	-moz-transition:all .5s ease;
}

header nav ul li a:hover{
	color:#ffffff;
	text-decoration:underline;
}

.home-banner{
	display:flex;
	justify-content:flex-start;
	align-items:center;
	height:100vh;
	max-height:1200px;
	/* padding-bottom:300px; */
	background:#000000;
	background-repeat: no-repeat !important;
	background-size: cover !important;
	background-position: center !important;
}

.home-banner .container{
	display:flex;
	justify-content:flex-start;
	align-items:center;
	padding-bottom:100px;
}

.home-banner .banner-content{
	width:50%;
}

.home-banner .banner-content h2{
	display:block;
	color:#ffffff;
	white-space: nowrap;
}

.home-banner .home-banner-img{
	width:50%;
}

.home-banner .home-banner-img-wrapper{
	width:50vw;
	height:500px;
	background:#142357;
	border-top-left-radius:500px;
	-moz-border-radius-topleft:500px;
	-webkit-border-top-left-radius:500px;
	border-bottom-left-radius:500px;
	-moz-border-radius-bottomleft:500px;
	-webkit-border-bottom-left-radius:500px;
	background-repeat:no-repeat !important;
	background-size:cover !important;
	background-position:center !important;
}

.home-about{
	padding-top:0;
	padding-bottom:50px;
	padding-bottom:0;
	margin-top:-190px;
	margin-top:0;
	position:relative;
	z-index:0;
}

.home-about .container{
	text-align:center;
	padding:50px 100px;
	background: rgb(50,79,62);
	background: -moz-linear-gradient(45deg,  rgba(50,79,62,1) 0%, rgba(16,39,90,1) 100%);
	background: -webkit-linear-gradient(45deg,  rgba(50,79,62,1) 0%,rgba(16,39,90,1) 100%);
	background: linear-gradient(45deg,  rgba(50,79,62,1) 0%,rgba(16,39,90,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#324f3e', endColorstr='#10275a',GradientType=1 );
	border-top-left-radius:100px;
	-moz-border-radius-topleft:100px;
	-webkit-border-top-left-radius:100px;
	border-bottom-right-radius:100px;
	-moz-border-radius-bottomright:100px;
	-webkit-border-bottom-right-radius:100px;
}

.home-about h2{
	color:#ffffff;
	margin-bottom:30px;
}

.home-about p{
	font-weight:300;
	color:#ffffff;
	margin:0 auto 20px;
}

.home-about a.more{
	color:#ffffff;
	background:url(../images/more-arrow-white.svg) 100% 12px no-repeat;
	background-size:18px;
}

.home-about-blocks{
	display:flex;
	justify-content:center;
	align-items:flex-start;
	gap:50px;
	margin-top:50px;
	margin-bottom:50px;
}

.home-about-block{
	color:#ffffff;
	width:100%;
}

.home-about-block:nth-child(1) .home-about-block-wrapper{
	text-align:center;
	padding:25px;
	margin-bottom:25px;
	background: #ffffff;
	border-top-left-radius:100px;
	-moz-border-radius-topleft:100px;
	-webkit-border-top-left-radius:100px;
	border-bottom-right-radius:100px;
	-moz-border-radius-bottomright:100px;
	-webkit-border-bottom-right-radius:100px;
}

.home-about-block:nth-child(2) .home-about-block-wrapper{
	text-align:center;
	padding:25px;
	margin-top:75px;
	margin-bottom:25px;
	background: #ffffff;
	border-radius:50px;
	-moz-border-radius:50px;
	-webkit-border-radius:50px;
}

.home-about-block:nth-child(3) .home-about-block-wrapper{
	text-align:center;
	padding:25px;
	margin-top:150px;
	margin-bottom:25px;
	background: #ffffff;
	border-top-right-radius:100px;
	-moz-border-radius-topright:100px;
	-webkit-border-top-right-radius:100px;
	border-bottom-left-radius:100px;
	-moz-border-radius-bottomleft:100px;
	-webkit-border-bottom-left-radius:100px;
}

.home-about-block-wrapper h4{
	color:#142357;
	margin-bottom:0;
}

.home-categories{
	padding-bottom:0;
}

.home-categories .container{
	display:flex;
	justify-content:center;
	align-items:center;
	flex-wrap:wrap;
	width:850px;
	position:relative;
}

.home-categories h4{
	text-align:center;
	display: inline-table;
	color:rgba(16,39,90,1);
	position:absolute;
	left:50%;
	top:50%;
	transform:translate(-50%, -50%);
	margin-top:60px;
}

.home-category{
	text-align:center;
	width:50%;
}

.home-category-block{
	display:flex;
	justify-content:center;
	align-items:center;
	width:180px;
	height:180px;
	margin:15px auto;
	background:#ffffff;
	border-radius:180px;
	-webkit-border-radius:180px;
	-moz-border-radius:180px;
	transition:all .5s ease;
	-webkit-transition:all .5s ease;
	-moz-transition:all .5s ease;
	position:relative;
}

.home-category-block.home-category-block-active,
.home-category-block:hover{
	background: rgb(50,79,62);
	background: -moz-linear-gradient(45deg,  rgba(50,79,62,1) 0%, rgba(16,39,90,1) 100%);
	background: -webkit-linear-gradient(45deg,  rgba(50,79,62,1) 0%,rgba(16,39,90,1) 100%);
	background: linear-gradient(45deg,  rgba(50,79,62,1) 0%,rgba(16,39,90,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#324f3e', endColorstr='#10275a',GradientType=1 );
}

.home-category:nth-child(1){
	width:100%;
}

.home-category:nth-child(1) .home-category-block{
	margin:0 auto -30px;
}

.home-category:nth-child(2) .home-category-block,
.home-category:nth-child(6) .home-category-block{
	margin-right:120px;
}

.home-category:nth-child(3) .home-category-block,
.home-category:nth-child(7) .home-category-block{
	margin-left:120px;
}

.home-category:nth-child(4) .home-category-block{
	float:left;
	margin:0;
}

.home-category:nth-child(5) .home-category-block{
	float:right;
	margin:0;
}

.home-category-block h6{
	white-space:nowrap;
	position:absolute;
}

.home-category:nth-child(1) .home-category-block h6{
	top:-50px;
}

.home-category:nth-child(2) .home-category-block h6,
.home-category:nth-child(4) .home-category-block h6,
.home-category:nth-child(6) .home-category-block h6{
	right:calc(100% + 30px);
}

.home-category:nth-child(3) .home-category-block h6,
.home-category:nth-child(5) .home-category-block h6,
.home-category:nth-child(7) .home-category-block h6{
	left:calc(100% + 30px);
}

.home-category-block svg path{
	fill:#142357;
}

.home-category-block.home-category-block-active svg path{
	fill:#ffffff;
}

.home-category-block-icon{
	width:180px;
	height:180px;
}

.home-category-block-icon img{
	position:absolute;
	left:50%;
	top:50%;
	transform:translate(-50%, -50%);
}

.home-category-block-icon img.icon-img{
	opacity:1;
}

.home-category-block-icon img.icon-hover-img{
	opacity:0;
}

.home-category-block:hover .home-category-block-icon img.icon-img,
.home-category-block-active .home-category-block-icon img.icon-img{
	opacity:0;
}

.home-category-block:hover .home-category-block-icon img.icon-hover-img,
.home-category-block-active .home-category-block-icon img.icon-hover-img{
	opacity:1;
}

.home-categories-details{
	text-align:center;
	padding-top:300px;
	position:relative;
}

.home-categories-details .container > span{
	display:block;
}

.home-categories-details-image{
	position:absolute;
	top:-300px;
	left:50%;
	width:50vw;
	height:600px;
	background:#142357;
	border-top-left-radius: 100px;
	-moz-border-radius-topleft: 100px;
	-webkit-border-top-left-radius: 100px;
	z-index:1;
	background-repeat:no-repeat !important;
	background-size:101% auto !important;
	background-position:center !important;
}

.home-categories-details .home-categories-details-block{
	color:#ffffff;
	padding:150px;
	background: rgb(50,79,62);
	background: -moz-linear-gradient(45deg,  rgba(50,79,62,1) 0%, rgba(16,39,90,1) 100%);
	background: -webkit-linear-gradient(45deg,  rgba(50,79,62,1) 0%,rgba(16,39,90,1) 100%);
	background: linear-gradient(45deg,  rgba(50,79,62,1) 0%,rgba(16,39,90,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#324f3e', endColorstr='#10275a',GradientType=1 );
	border-top-left-radius: 100px;
	-moz-border-radius-topleft: 100px;
	-webkit-border-top-left-radius: 100px;
	border-bottom-right-radius: 100px;
	-moz-border-radius-bottomright: 100px;
	-webkit-border-bottom-right-radius: 100px;
}

.home-categories-details .home-categories-details-block h3{
	position:relative;
	z-index:2;
}

.home-brands{
	text-align:center;
}

.home-brands h2{
	display:inline-block;
	background: rgb(50,79,62);
	background: -moz-linear-gradient(45deg,  rgba(50,79,62,1) 0%, rgba(16,39,90,1) 100%);
	background: -webkit-linear-gradient(45deg,  rgba(50,79,62,1) 0%,rgba(16,39,90,1) 100%);
	background: linear-gradient(45deg,  rgba(50,79,62,1) 0%,rgba(16,39,90,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#324f3e', endColorstr='#10275a',GradientType=1 );
	-webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;
	margin-bottom: 30px;
}

.home-brands p{
	font-weight:300;
	max-width:600px;
	margin:0 auto  60px;
}

.home-brands-lists{
	display:grid;
	grid-template-columns:1fr 1fr 1fr 1fr 1fr;
	gap:0px 0px;
	margin:0 auto  60px;
}

.home-brands-list-block{
	padding:0 30px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.slick-dots li button::before {
	font-size: 12px !important;
	color:#142357 !important;
}

footer{
	float:left;
	width:100%;
	padding:100px 0;
	background: rgb(50,79,62);
	background: -moz-linear-gradient(45deg,  rgba(50,79,62,1) 0%, rgba(16,39,90,1) 100%);
	background: -webkit-linear-gradient(45deg,  rgba(50,79,62,1) 0%,rgba(16,39,90,1) 100%);
	background: linear-gradient(45deg,  rgba(50,79,62,1) 0%,rgba(16,39,90,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#324f3e', endColorstr='#10275a',GradientType=1 );	
}

footer .container{
	display:flex;
	justify-content:space-between;
	align-items:flex-end;
	flex-wrap:wrap;
}

footer .footer-block{
	font-size:15px;
	color:#ffffff;
	width:50%;
}

footer .footer-block h6{
	margin-bottom:20px;
}

footer .footer-block p{
	font-size:15px;
	color:#ffffff;
	margin-bottom:20px;
}

footer .footer-block p img{
	display:inline-block;
	width:18px;
	height:auto;
	margin-bottom:-3px;
}

footer .footer-block a{
	font-size:15px;
	color:#ffffff;
	text-decoration:underline;
}

footer .footer-block p label{
	float:left;
	width:15px;
	margin:0 10px 20px 0;
}

footer .footer-block:nth-child(2){
	text-align:right;
}

footer .footer-block:nth-child(2) a{
	display:inline-block;
	width:28px;
	height:28px;
	margin-left:5px;
	margin-bottom:20px;
	background:#ffffff;
	border-radius:28px;	
	transition:all .5s ease;
	-webkit-transition:all .5s ease;
	-moz-transition:all .5s ease;
}

footer .footer-block:nth-child(2) a:hover{
	transform:scale(1.2);
}

footer .footer-block:nth-child(2) a.facebook{
	background:transparent url(../images/facebook.svg) center no-repeat;
}

footer .footer-block:nth-child(2) a.twitter{
	background:transparent url(../images/twitter.svg) center no-repeat;
}

footer .footer-block:nth-child(2) a.youtube{
	background:transparent url(../images/youtube.svg) center no-repeat;
}

footer .footer-block:nth-child(2) a.linkedin{
	background:transparent url(../images/linkedin.svg) center no-repeat;
}

footer .footer-block:nth-child(3){
	text-align:center;
	padding-top:50px;
	width:100%;
}

footer .footer-block:nth-child(3) p{
	font-size:13px;
	font-weight:300;
	margin-bottom:0;
	opacity:.6;
}

footer .footer-block:nth-child(3) a{
	font-size:13px;
	font-weight:300;
	text-decoration:none;
}

.about-banner{
	text-align:center;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	height: 100vh;
	max-height: 600px;
	background: #000000;
	background-repeat:no-repeat !important;
	background-size:cover !important;
	background-position:center !important;
}

.about-banner h2 {
	color: #ffffff;
}

.about-brains .container{
	display:flex;
	justify-content:space-between;
	align-items:flex-start;
}

.about-brains-block{
	width:calc(50% - 30px);
}

.about-us h3,
.about-brains-block h3{
	line-height:1;
	margin-bottom:30px;
}

.about-us p,
.about-brains-block p{
	font-weight: 300;
	margin-bottom:20px;
}

.about-brains-block .about-brains-block-image{
	margin-top:80px;
	overflow:hidden;
	border-top-left-radius: 100px;
	-moz-border-radius-topleft: 100px;
	-webkit-border-top-left-radius: 100px;
	border-bottom-right-radius: 100px;
	-moz-border-radius-bottomright: 100px;
	-webkit-border-bottom-right-radius: 100px;
}

.about-brains-block .about-brains-block-image img{
	height:auto;
}

.about-slogan{
	padding:0;
}

.about-slogan .container{
	text-align: center;
	padding: 100px;
	background: rgb(50,79,62);
	background: -moz-linear-gradient(45deg, rgba(50,79,62,1) 0%, rgba(16,39,90,1) 100%);
	background: -webkit-linear-gradient(45deg, rgba(50,79,62,1) 0%,rgba(16,39,90,1) 100%);
	background: linear-gradient(45deg, rgba(50,79,62,1) 0%,rgba(16,39,90,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#324f3e', endColorstr='#10275a',GradientType=1 );
	border-top-left-radius: 100px;
	-moz-border-radius-topleft: 100px;
	-webkit-border-top-left-radius: 100px;
	border-bottom-right-radius: 100px;
	-moz-border-radius-bottomright: 100px;
	-webkit-border-bottom-right-radius: 100px;
}

.about-slogan p{
	font-size:34px;
	color:#ffffff;
	padding-left:110px;
	padding-right:110px;
}

.about-journey{
	text-align:center;
}

.about-journey h2{
	margin-bottom:30px;
}

.about-journey p{
	font-weight: 300;
	margin-bottom:20px;
}

.about-key-benefits{
	color:#ffffff;
	text-align: center;
	padding: 100px;
	margin-top:50px;
	background: rgb(50,79,62);
	background: -moz-linear-gradient(45deg, rgba(50,79,62,1) 0%, rgba(16,39,90,1) 100%);
	background: -webkit-linear-gradient(45deg, rgba(50,79,62,1) 0%,rgba(16,39,90,1) 100%);
	background: linear-gradient(45deg, rgba(50,79,62,1) 0%,rgba(16,39,90,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#324f3e', endColorstr='#10275a',GradientType=1 );
	border-top-left-radius: 100px;
	-moz-border-radius-topleft: 100px;
	-webkit-border-top-left-radius: 100px;
	border-bottom-right-radius: 100px;
	-moz-border-radius-bottomright: 100px;
	-webkit-border-bottom-right-radius: 100px;
}

.about-key-benefits h5{
	margin-bottom:30px;
}

.about-key-benefits ul{
	text-align:left;
}

.about-key-benefits li{
	padding-left:38px;
	margin-bottom:10px;
	background:url(../images/tick-rev.svg) left 1px no-repeat;
	background-size:26px;
}

.brands-banner{
	text-align:center;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	height: 100vh;
	max-height: 600px;
	background: #000000;
	background-repeat:no-repeat !important;
	background-size:cover !important;
	background-position:center !important;
	background-size: contain !important;
	background-repeat: repeat !important;
}

.brands-banner h2 {
	color: #ffffff;
}

.brand{
	border-bottom:1px solid rgba(20,35,87,.3);
	margin-bottom: 30px;
}

.brand .accordian-content{
	display:none;
}

.brand .accordian-content.expand{
	display:block;
}

.brand h4{
	font-size:50px;
	margin-bottom:30px;
}

.brand-item{
	display:grid;
	grid-template-columns:1fr 1fr 1fr 1fr 1fr;
	gap:0px 0px;
	margin:0 auto  60px;
}

.brand-item-block{
	padding:0 30px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.products-banner{
	text-align:center;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	height: 100vh;
	max-height: 600px;
	background: #000000;
	background-repeat:no-repeat !important;
	background-size:cover !important;
	background-position:center !important;
}

.products-banner h2 {
	color: #ffffff;
}

.product-banner{
	text-align:center;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	height: 100vh;
	max-height: 600px;
	background: #000000;
	background-repeat:no-repeat !important;
	background-size:cover !important;
	background-position:center !important;
}

.product-banner h2 {
	color: #ffffff;
}

.product-details{
	text-align:center;
}

.contact-banner{
	text-align:center;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	height: 100vh;
	max-height: 800px;
	max-height: 600px;
	background: #000000;
	background-repeat:no-repeat !important;
	background-size:cover !important;
	background-position:center 30% !important;
}

.contact-banner h2 {
	color: #ffffff;
}

.contact-addresses{
	/* padding-bottom:0;
	margin-bottom:-100px; */
}

.contact-addresses .container{
	/* padding:125px 0 350px; */
	padding-left: 0px;
	padding-right: 0px;
	display:flex;
	justify-content:space-between;
	align-items:center;
}

.contact-addresses-block{
	color:#ffffff;
	text-align:center;
	width:50%;
	padding:100px;
	background: rgb(50,79,62);
	background: -moz-linear-gradient(45deg, rgba(50,79,62,1) 0%, rgba(16,39,90,1) 100%);
	background: -webkit-linear-gradient(45deg, rgba(50,79,62,1) 0%,rgba(16,39,90,1) 100%);
	background: linear-gradient(45deg, rgba(50,79,62,1) 0%,rgba(16,39,90,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#324f3e', endColorstr='#10275a',GradientType=1 );
	border-top-left-radius: 100px;
	-moz-border-radius-topleft: 100px;
	-webkit-border-top-left-radius: 100px;
	border-bottom-right-radius: 100px;
	-moz-border-radius-bottomright: 100px;
	-webkit-border-bottom-right-radius: 100px;	
}

.contact-addresses-block:nth-child(1){
	/* margin-top:-450px; */
}

.contact-addresses-block:nth-child(2){
	/* margin-bottom:-450px; */
}

.contact-addresses-block h4{
	margin-bottom:30px;
}

.contact-addresses-block p{
	margin-bottom:20px;
}

.contact-addresses-block p:last-child{
	margin-bottom:0;
}

.contact-addresses-block a{
	color:#ffffff;
}

.need-speedy-solution .container{
	display:flex;
	justify-content:space-between;
	align-items:center;
}

.need-speedy-solution-block{
	width:50%;
}

.need-speedy-solution-block h3{
	margin-bottom:30px;
}

.need-speedy-solution-block p{
	font-weight: 300;
	margin-bottom:30px;
}

.contact-form{
	text-align:center;
	padding-top:0;
}

.contact-form h2{
	margin-bottom:30px;
}

.contact-form p{
	font-weight: 300;
	max-width: 600px;
	margin: 0 auto 60px;
}

.contact-form-wrapper{
	max-width:850px;
	margin:0 auto;
}

.contact-form-wrapper ul{
	display:flex;
	justify-content:space-between;
	align-items:flex-start;
	flex-wrap:wrap;
}

.contact-form-wrapper ul li{
	text-align:left;
	width:calc(50% - 15px);
	margin-bottom:30px;
}

.contact-form-wrapper ul li:last-child{
	margin-bottom:0;
}

.contact-form-wrapper ul li.full{
	width:100%;
}

.contact-form-wrapper ul li label{
	font-size:16px;
	display:block;
	margin-bottom:5px;
}

.contact-form-wrapper em{
	color:red;
	font-style:normal;
}

.contact-form-wrapper ul li input[type="text"],
.contact-form-wrapper ul li input[type="password"],
.contact-form-wrapper ul li input[type="email"],
.contact-form-wrapper ul li textarea,
.contact-form-wrapper ul li select{
	font-family: 'Poppins', sans-serif;
	font-size:18px;
	font-weight: 400;
	color:#142357;
	line-height:1.5;
	padding:10px 15px;
	width:100%;
	background:#ffffff;
	border:1px solid #142357;
	border-radius:5px;
	-webkit-border-radius:5px;
	-moz-border-radius:5px;
}

.contact-form-wrapper ul li select{
	-webkit-appearance:none;
	background:url(../images/downarrow.svg) calc(100% - 10px) center no-repeat;
	background-size:22px;
}

.contact-form-wrapper ul li textarea{
	height:150px;
}

.contact-form-wrapper ul li input[type="submit"],
.contact-form-wrapper ul li button,
.button{
	cursor:pointer;
	font-family: 'Poppins', sans-serif;
	font-size:16px;
	font-weight: 400;
	text-transform:uppercase;
	color:#ffffff;
	padding:15px 50px;
	border:0;
	border-radius:60px;
	-webkit-border-radius:60px;
	-moz-border-radius:60px;
	background: rgb(50,79,62);
	background: -moz-linear-gradient(45deg, rgba(50,79,62,1) 0%, rgba(16,39,90,1) 100%);
	background: -webkit-linear-gradient(45deg, rgba(50,79,62,1) 0%,rgba(16,39,90,1) 100%);
	background: linear-gradient(45deg, rgba(50,79,62,1) 0%,rgba(16,39,90,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#324f3e', endColorstr='#10275a',GradientType=1 );
	-webkit-appearance:none;
	-moz-appearance:none;
	appearance:none;
}

.button:hover{
	color:#ffffff;
}

.contact-form-wrapper ul li .error-msg{
	font-size:16px;
	color:red;
}

.contact-form-wrapper .statusMessage{
	color:#ffffff;
	display:flex;
	justify-content:center;
	align-items:center;
	margin-bottom:50px;
	background: rgb(50,79,62);
	background: -moz-linear-gradient(45deg, rgba(50,79,62,1) 0%, rgba(16,39,90,1) 100%);
	background: -webkit-linear-gradient(45deg, rgba(50,79,62,1) 0%,rgba(16,39,90,1) 100%);
	background: linear-gradient(45deg, rgba(50,79,62,1) 0%,rgba(16,39,90,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#324f3e', endColorstr='#10275a',GradientType=1 );
	border-radius:6px;
}

.services-banner{
	text-align:center;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	height: 100vh;
	max-height: 700px;
	max-height: 600px;
	background: #000000;
	background-repeat:no-repeat !important;
	background-size:cover !important;
	background-position:center !important;
}

.services-banner h2 {
	color: #ffffff;
}

.services{
	/* padding:0;
	margin:-110px 0 -50px 0; */
	padding-bottom: 50px;
}

.services .container{
	display:flex;
	justify-content:space-between;
	align-items:flex-start;
	flex-wrap:wrap;
}

.services-block{
	padding:50px 0;
	width:50%;
}

.services-block-full{
	padding:50px 75px;
	width:100%;
}

.services-block-title{
	color:#ffffff;
	text-align:center;
	padding:75px 0;
	background: rgb(50,79,62);
	background: -moz-linear-gradient(45deg, rgba(50,79,62,1) 0%, rgba(16,39,90,1) 100%);
	background: -webkit-linear-gradient(45deg, rgba(50,79,62,1) 0%,rgba(16,39,90,1) 100%);
	background: linear-gradient(45deg, rgba(50,79,62,1) 0%,rgba(16,39,90,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#324f3e', endColorstr='#10275a',GradientType=1 );
	border-top-left-radius: 100px;
	-moz-border-radius-topleft: 100px;
	-webkit-border-top-left-radius: 100px;
	border-bottom-right-radius: 100px;
	-moz-border-radius-bottomright: 100px;
	-webkit-border-bottom-right-radius: 100px;
}

.services-block h6{
	margin-bottom:30px;
}

.services-block p{
	font-weight:300;
	margin-bottom:20px;
}

.services-block p:last-child{
	margin-bottom:0;
}

.services-block li{
	padding-left:38px;
	margin-bottom:10px;
	background:url(../images/tick.svg) left 1px no-repeat;
	background-size:26px;
}

.services-block-logistics-wrapper{
	padding-bottom:100px;
}

.services-block-plane{
	position: absolute;
	bottom: 0;
	right: 0;
}

.services-block-image-wrapper{
	width:300px;
	height:300px;
	margin:0 auto;
	background: rgb(50,79,62);
	background: -moz-linear-gradient(45deg, rgba(50,79,62,1) 0%, rgba(16,39,90,1) 100%);
	background: -webkit-linear-gradient(45deg, rgba(50,79,62,1) 0%,rgba(16,39,90,1) 100%);
	background: linear-gradient(45deg, rgba(50,79,62,1) 0%,rgba(16,39,90,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#324f3e', endColorstr='#10275a',GradientType=1 );
	background-repeat:no-repeat !important;
	background-size:cover !important;
	background-position:center !important;
	border-top-right-radius: 100px;
	-moz-border-radius-topright: 100px;
	-webkit-border-top-right-radius: 100px;
	border-bottom-left-radius: 100px;
	-moz-border-radius-bottomleft: 100px;
	-webkit-border-bottom-left-radius: 100px;
	overflow:hidden;
}

.services-block-image-wrapper img{
	height:auto;
}

.terms-conditions-banner{
	text-align:center;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	height: 100vh;
	max-height: 600px;
	background: #000000;
	background-repeat:no-repeat !important;
	background-size:cover !important;
	background-position:center !important;
}

.terms-conditions-banner h2 {
	color: #ffffff;
}

.terms p{
	font-size:16px;
	margin-bottom:20px;
}

.terms ul,
.terms ol{
	text-align:left;
	margin-left:30px;
}

.terms li{
	font-size:16px;
	list-style-type:auto;
	margin-bottom:20px;
}

.page-banner{
	text-align:center;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	height: 100vh;
	max-height: 600px;
	background: rgba(16,39,90,1);
	background-repeat:no-repeat !important;
	background-size:cover !important;
	background-position:center !important;
	background-size: contain !important;
	background-repeat: repeat !important;
}

.page-banner h2 {
	color: #ffffff;
}

.product-banner h2{
	font-size: 68px;
}

.psm-products .product{
	display:block;
	padding:0 0 20px 0;
	margin:0 0 20px 0;
	border-bottom:1px solid rgba(20,35,87,.3);
}

.psm-products .product:last-child{
	padding:0;
	margin:0;
	border-bottom:0px solid rgba(20,35,87,.3);
}

.psm-products h2{
	font-size:36px;
	display:inline-block;
	color: #142357;
	margin-bottom:15px;
}

.psm-products h3{
	font-size:24px;
	display:inline-block;
	color: #142357;
}

.pagination{
	display:flex;
	justify-content:flex-end;
	align-items:center;
	width:100%;
	margin:50px 0 0 0;
}

.pagination-item{
	display:inline-block;
	text-align:center;
	line-height:28px;
	width:28px;
	height:28px;
	vertical-align:middle;
	margin:0 3px;
	cursor:pointer;
}

.pagination-item.disabled{
	opacity:0;
	visibility: hidden;
}

.pagination-item.selected{
	color:#ffffff;
	font-weight:700;
	background: rgb(50,79,62);
	background: -moz-linear-gradient(45deg, rgba(50,79,62,1) 0%, rgba(16,39,90,1) 100%);
	background: -webkit-linear-gradient(45deg, rgba(50,79,62,1) 0%,rgba(16,39,90,1) 100%);
	background: linear-gradient(45deg, rgba(50,79,62,1) 0%,rgba(16,39,90,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#324f3e', endColorstr='#10275a',GradientType=1 );
	background-repeat:no-repeat !important;
	background-size:cover !important;
	background-position:center !important;
	border-radius:28px;
	-webkit-border-radius:28px;
	-moz-border-radius:28px;
}

.previous{
	display:inline-block;
	width:100%;
	height:100%;
	background:url(../images/prev-arrow.svg) center no-repeat;
	background-size:18px;
}

.next{
	display:inline-block;
	width:100%;
	height:100%;
	background:url(../images/next-arrow.svg) center no-repeat;
	background-size:18px;
}

@media screen and (max-height:1200px) {
	.home-banner{
		/* padding-bottom:150px; */
	}
	.home-banner .container{
		padding-bottom:0;
	}
}

@media screen and (max-height:800px) {
	.home-banner{
		padding-bottom:0;
	}
	.home-about{
		margin-top:0;
		padding-top: 0;
	}
}

@media screen and (max-width:1280px) {
	h2{
		font-size: 80px;
	}
	h3{
		font-size: 70px;
	}
}

@media screen and (max-width:1280px) {
	.container{
		padding-left:50px;
		padding-right:50px;
	}
}

@media screen and (max-width:1080px) {
	header{
		padding:25px 0;
	}
	header .container{
		align-items:center;
	}
	header .logo img{
		width: 128px;
	}
	.psm-section{
		padding-top:50px;
		padding-bottom:50px;
	}
	.home-about{
		padding-left:50px;
		padding-right:50px;
	}
	.home-about .container,
	.about-key-benefits{
		padding:50px;
	}
	.home-about h2{
		margin-bottom:20px;
	}
	.home-category:nth-child(2) .home-category-block, .home-category:nth-child(6) .home-category-block{
		margin-right: 100px;
	}
	.home-category:nth-child(3) .home-category-block, .home-category:nth-child(7) .home-category-block{
		margin-left: 100px;
	}
	.home-category:nth-child(3) .home-category-block h6, .home-category:nth-child(5) .home-category-block h6, .home-category:nth-child(7) .home-category-block h6{
		left:100%;
	}
	.home-category:nth-child(2) .home-category-block h6, .home-category:nth-child(4) .home-category-block h6, .home-category:nth-child(6) .home-category-block h6{
		right:100%;
	}
	.home-categories-details{
		padding-top: 300px;
		padding-bottom:0;
	}
	.home-categories-details-image{
		width: calc(100vw - 400px);
		height: 400px;
		left: 400px;
	}
	.home-categories-details .home-categories-details-block{
		padding:100px;
	}
	.home-brands h2{
		margin-bottom:20px;
	}
	.home-brands-lists{
		margin:0 auto;
	}
	footer{
		padding:50px 0;
	}
	footer .footer-block:nth-child(3){
		padding-top:30px;
	}
	.about-banner,
	.brands-banner,
	.products-banner,
	.product-banner,
	.services-banner,
	.contact-banner,
	.terms-conditions-banner,
	.page-banner{
		max-height:400px;
	}
	.about-brains-block h3{
		margin-bottom:20px;
	}
	.about-slogan{
		padding-left:50px;
		padding-right:50px;
		padding-top: 0;
		padding-bottom:0;
	}
	.about-slogan .container{
		padding:50px;
	}
	.about-journey h2{
		margin-bottom:20px;
	}
	.services-block-title{
		padding:50px 0;
	}
	.contact-banner{
		/* max-height:600px; */
	}
	.contact-addresses{
		padding-left:50px;
		padding-right:50px;
		padding-bottom:0;
	}
	.contact-addresses-block{
		padding:50px;
	}
	.contact-addresses-block h4{
		margin-bottom:20px;
	}
	.contact-addresses .container{
		/* padding:25px 0 265px 0; */
	}
	.contact-addresses-block:nth-child(1){
		/* margin-top: -175px; */
	}
	.contact-addresses-block:nth-child(2){
		/* margin-bottom: -350px; */
	}
	.contact-form{
		padding-top:0;
	}
	.contact-form h2{
		margin-bottom:20px;
	}
	.need-speedy-solution-block h3{
		margin-bottom:20px;
	}
	.psm-products h2{
		font-size: 38px;
		margin-bottom:5px;
	}
	.product-banner{
		padding-top:150px;
	}
	.product-banner h2{
		font-size: 58px;
		line-height:1;
	}
}

@media screen and (max-width:980px) {
	header .logo img {
		width: 128px;
	}
	.home-banner{
		height:80vh;
	}
	.home-banner .banner-content{
		text-align:center;
		width:100%;
	}
	.home-banner .home-banner-img{
		display:none;
	}
	.home-category-block{
		margin:0 auto;
	}
	.home-category-block h6{
		display:none;
	}
	.home-categories-details-image{
		left:200px;
		width: calc(100vw - 200px);
		height: 350px;
	}
	.home-brands-lists{
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}
	.home-about-blocks{
		flex-wrap:wrap;
	}
	.home-about-block:nth-child(1) .home-about-block-wrapper,
	.home-about-block:nth-child(2) .home-about-block-wrapper,
	.home-about-block:nth-child(3) .home-about-block-wrapper{
		margin:0 auto 15px;
		max-width:400px;
	}
	.about-brains .container{
		flex-wrap:wrap;
	}
	.about-brains-block{
		width:100%;
	}
	.about-brains-block .about-brains-block-image{
		margin:20px auto 0;
		max-width:400px;
	}
	.brand-item{
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}
	.services-block-full{
		padding:50px 25px;
	}
	.services-block{
		width:100%;
	}
	.services-block.services-block-blank{
		display:none;
	}
	.services-block-title{
		max-width:50%;
	}
	.services-block-title-right{
		margin-left: auto;
	}
	.services-block-logistics-wrapper{
		padding-bottom:0;
	}
	.services-block-logistics{
		width:100%;
		max-width:600px;
		height:auto;
	}
	.services-block-plane-wrapper{
		padding:0;
	}
	.services-block-plane{
		position:relative;
		bottom:inherit;
		right:inherit;
		margin-left:auto;
		margin-right:0;
		height:auto;
	}
	.need-speedy-solution{
		padding-top:0;
	}
	.contact-addresses{
		padding-bottom:50px;
	}
}

@media screen and (max-width:800px) {
	h2{
		font-size: 70px;
	}
	h3{
		font-size: 50px;
	}
	h4{
		font-size: 50px;
	}
	h5{
		font-size: 28px;
	}
	.container{
		padding-left: 30px;
		padding-right: 30px;
	}
	header{
		padding:30px 0;
	}
	nav{
		opacity:0;
		visibility:hidden;
		position:fixed;
		left:0;
		top:0;
		width:100%;
		height:100vh;
		background:rgba(16,39,90,.9);
		display:flex;
		justify-content:center;
		align-items:center;
	}
	nav.visible{
		opacity:1;
		visibility:visible;
	}
	header nav ul{
		width:100%;
	}
	header nav ul li{
		text-align:center;
		display:block;
		margin:0;
	}
	header nav ul li a{
		font-size:18px;
		padding:10px;
		display:block;
	}
	.burger {
		display: block;
	}
	.home-banner{
		height:75vh;
	}
	.home-about{
		padding-left: 30px;
		padding-right: 30px;
	}
	a.more{
		margin-top:30px;
	}
	.home-categories .container{
		width:100%;
	}
	.home-category:nth-child(1),
	.home-category:nth-child(2),
	.home-category:nth-child(3),
	.home-category:nth-child(4),
	.home-category:nth-child(5),
	.home-category:nth-child(6),
	.home-category:nth-child(7){
		padding:10px;
		width:auto;
	}
	.home-category:nth-child(1) .home-category-block,
	.home-category:nth-child(2) .home-category-block, 
	.home-category:nth-child(3) .home-category-block, 
	.home-category:nth-child(4) .home-category-block, 
	.home-category:nth-child(5) .home-category-block, 
	.home-category:nth-child(6) .home-category-block,
	.home-category:nth-child(7) .home-category-block{
		width:120px;
		height:120px;
		margin:0 auto;
	}
	.home-category-block-icon{
		width:120px;
		height:120px;
	}
	.home-category-block-icon img{
		width: 70%;
		height: 70%;
	}
	.home-category:nth-child(4) .home-category-block,
	.home-category:nth-child(5) .home-category-block{
		float:none;
	}
	.home-categories h4{
		display:inline-block;
		position:relative;
		left:inherit;
		top:inherit;
		transform:translate(0, 0);
		width:100%;
		margin:30px 0 0 0;
		text-align:center;
	}
	.home-categories-details .home-categories-details-block{
		padding:50px;
	}
	.home-categories-details{
		padding-top:250px;
	}
	.home-categories-details-image{
		height: 280px;
		top: -250px;
	}
	.home-brands-lists{
		grid-template-columns: 1fr 1fr 1fr;
	}
	.home-brands-list-block{
		padding: 0;
	}
	.about-banner, .brands-banner, .products-banner, .product-banner, .services-banner, .contact-banner, .terms-conditions-banner, .page-banner{
		padding-top:100px;
		max-height:300px;
	}
	.about-slogan p{
		font-size: 24px;
		padding-left: 50px;
		padding-right: 50px;
	}
	.brand-item{
		grid-template-columns: 1fr 1fr 1fr;
		margin:0 auto 30px;
	}
	.brand-item-block{
		padding:0 10px;
	}
	.brand h4{
		font-size:40px;
	}
	.services{
		/* margin:-75px 0 -50px 0; */
	}
	.services-block-title{
		padding:30px 0;
	}
	.services-block-image-wrapper{
		width: 250px;
		height: 250px;
		margin: 0 auto;
	}
	.services-block-full{
		padding:30px 0;
	}
	.need-speedy-solution{
		padding-top: 80px;
		padding-bottom: 30px;
	}
	.need-speedy-solution .container{
		flex-wrap:wrap;
	}
	.need-speedy-solution-block{
		width:100%;
	}
	.need-speedy-solution-block:nth-child(1){
		margin-bottom:30px;
	}
	.need-speedy-solution-block img{
		width:100%;
		max-width:400px;
		height:auto;
	}
	.contact-addresses{
		padding-left: 30px;
		padding-right: 30px;
	}
	.contact-addresses .container{
		flex-wrap:wrap;
		padding:0;
	}
	.contact-addresses-block{
		width:100%;
	}
	.contact-addresses-block:nth-child(2){
		margin-top:30px;
		margin-bottom:0;
	}
	.contact-form p{
		margin: 0 auto 30px;
	}
	.contact-form-wrapper ul{
		flex-wrap:wrap;
	}
	.contact-form-wrapper ul li{
		width:100%;
		margin-bottom:20px;
	}
	.psm-products h2{
		font-size: 28px;
	}
	.psm-products h3{
		font-size: 18px;
	}
}

@media screen and (max-width:636px) {
	h2{
		font-size: 46px;
	}
	h3{
		font-size: 38px;
	}
	h4{
		font-size: 38px;
	}
	header .logo img {
		width: 100px;
	}
	.home-banner{
		height:50vh;
	}
	.home-about{
		padding-left:0;
		padding-right:0;
		padding-bottom:0;
	}
	.home-about .container,
	.about-key-benefits{
		padding:50px 30px;
	}
	.home-categories h4{
		margin:0;
	}
	.home-categories-details-image{
		left:100px;
		width: calc(100vw - 100px);
	}
	.home-brands-lists{
		grid-template-columns: 1fr 1fr;
	}
	.slick-slide img{
		max-width:75%;
	}
	footer .footer-block{
		width:100%;
	}
	footer .footer-block:nth-child(2){
		text-align:left;
	}
	footer .footer-block:nth-child(2) a{
		margin-left:0;
	}
	.about-banner, .brands-banner, .products-banner, .product-banner, .services-banner, .contact-banner, .terms-conditions-banner, .page-banner{
		max-height:250px;
	}
	.about-slogan{
		padding-left:30px;
		padding-right:30px;
	}
	.about-slogan p{
		padding-left:0px;
		padding-right:0px;
	}
	.brand-item{
		grid-template-columns: 1fr 1fr;
	}
	.brand h4{
		font-size:30px;
	}
	.services-block-title{
		width:100%;
		max-width: 300px;
	}
	.services-block-image-wrapper{
		margin: 30px auto;
	}
	.services-block-plane{
		margin-top:30px;
	}
	.contact-banner{
		/* max-height: 400px; */
	}
	.contact-addresses{
		padding-left: 0;
		padding-right: 0;
		padding-bottom: 0;
	}
	.about-brains .container,
	.services .container{
		padding-left: 0;
		padding-right: 0;
	}
	.services-block-title-right{
		margin-left:auto;
		margin-right:auto;
	}
	.services{
		padding:0;
	}
	.services-block-full{
		padding-bottom:0;
	}
	.product-banner{
		max-height:300px;
	}
	.product-banner h2{
		font-size: 38px;
	}
	.pagination{
		display: block;
		text-align: center;
		margin:30px 0 0 0;
	}
	.pagination-item{
		margin:0;
	}
}